import { createRouter, createWebHistory } from 'vue-router'
//import NProgress from 'nprogress';
import IndexPage from "./pages/IndexPage";

const routes = [
    {
        path: '/',
        name: 'index',
        component: IndexPage,
        meta: {
            title: 'Registration'
        }
    },

]

/*router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})*/

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;
