<template>
    <div class="col-12">
        <div class="inner text-center">
            <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
            </div>
            <br>
            {{ $t('loading.text') }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>
