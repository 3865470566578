console.log('asd');

//import './bootstrap';
import { createApp } from 'vue'
import App from './views/App';
import router from './routes';
import BootstrapVue from "bootstrap-vue-3";
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import cs from "./lang/cs";
import en from "./lang/en";
import VueCookies from 'vue3-cookies'

const messages = {
    'en': en,
    'cs': cs,
};

const i18n = createI18n({
    allowComposition: false,
    locale: window.laravel.locale,
    fallbackLocale: 'cs',
    messages
});

axios.defaults.headers.common = {
    'X-CSRF-TOKEN': laravel.csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + laravel.apiToken,
    'ApiLocale': laravel.locale,
};
axios.defaults.timeout = 1000*60*10;

//Vue.config.productionTip = false;

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(VueCookies);
app.use(BootstrapVue)
app.mount("#app");

