<template>
    <div class="row">

        <Error v-if="error" :message="errorMessage"  />
        <Notice v-if="notice" :message="noticeMessage" />

        <template v-if="!error && !notice">
            <Loading v-if="!loaded.networkInfo"/>

            <!-- TODO
             - (done) u registrované stanice zobrazit - stanice je již registrována  - místo hlášky o chybném subnetu
             - u kolejních počítačů dovolit odregistrování stanice (na stránce viz výše)
             - (done) odhlášení před registrací zařízení (na stánce s výběrem dočasné registrace)
             -->

            <template v-if="loaded.networkInfo && !subnetAwaiting && !subnetActive /*&& (!historyId || zoneActive || zoneAwaiting)*/ ">

                <div class="col-12 authentication-options" v-if="authenticationToken === null">
                    <div class="inner">
                        <h2>1. {{ $t('index.authentication') }}</h2>
                        <p class="mt-1 mb-0">{{ $t('index.pick_one') }}:</p>
                        <div class="row">
                            <template v-if="zone === 'knet.vse.cz'">
                                <div class="col-md-6 col-12 mt-3">
                                    <div class="authentication-option"
                                         :class="identityProvider === 'vse' ? 'selected' : ''"
                                         @click="setIdentityProvider('vse')">
                                        <h3>{{ $t('index.idps.vse') }}</h3>
                                        <p>{{ $t('index.idps.vse_text') }}</p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 mt-3">
                                    <div class="authentication-option"
                                         :class="identityProvider === 'iskam' ? 'selected' : ''"
                                         @click="setIdentityProvider('iskam')">
                                        <h3>{{ $t('index.idps.iskam') }}</h3>
                                        <p>{{ $t('index.idps.iskam_text') }}</p>
                                    </div>
                                </div>
                               <!-- <div class="col-md-4 col-12 mt-3">
                                    <div class="authentication-option"
                                         :class="identityProvider === 'guest' ? 'selected' : ''"
                                         @click="setIdentityProvider('guest')">
                                        <h3>{{ $t('index.idps.guest') }}</h3>
                                        <p>{{ $t('index.idps.guest_text') }}</p>
                                    </div>
                                </div>-->
                            </template>

                            <div class="col-12 mt-3" v-if="identityProvider">

                                <p v-if="identityProvider === 'vse'">{{ $t('index.idps.vse_auth_info') }}</p>
                                <p v-if="identityProvider === 'iskam'">{{ $t('index.idps.iskam_auth_info') }}</p>
                                <p v-if="identityProvider === 'guest'">{{ $t('index.idps.guest_auth_info') }}</p>

                                <div class="row justify-content-center mt-3">
                                    <div class="col-lg-4 col-md-6 col-sm-8 col-12">
                                        <form v-on:submit.prevent="authenticate">
                                            <div class="alert alert-warning" v-if="showFailedAuth">
                                                {{ $t('index.incorrect_auth') }}
                                            </div>
                                            <div class="form-group">
                                                <label for="username">{{ $t('index.username') }}</label>
                                                <input type="text" class="form-control" v-model="username" id="username" />
                                            </div>
                                            <div class="form-group">
                                                <label for="password">{{ $t('index.password') }}</label>
                                                <input type="password" class="form-control" v-model="password" id="password" />
                                            </div>

                                            <button class="btn btn-primary form-control"
                                                    @click.prevent="authenticate"
                                                    :disabled="authenticationButtonDisabled">
                                                <template v-if="waitingFor.authentication">
                                                    {{ $t('index.authenticating') }}
                                                </template>
                                                <template v-else>
                                                    {{ $t('index.authenticate_button') }}
                                                </template>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-12 registration" v-if="authenticationToken !== null /*&& rooms !== null*/">
                    <div class="inner">
                        <div class="row">
                            <div class="col-md-8">
                                <h2>2. {{ $t('index.registration') }}</h2>
                            </div>
                            <div class="col-md-4 text-right">
                                <button class="btn btn-primary mt-2" @click="logout">{{ $t('index.logout') }}</button>
                            </div>
                        </div>

                        <p class="mt-2">{{ $t('index.authenticated_as') }}: <strong>{{ username }}</strong></p>
                        <p>{{ $t('index.using') }}:
                            <strong>
                                <template v-if="identityProvider === 'vse'">{{ $t('index.idps.vse') }}</template>
                                <template v-if="identityProvider === 'iskam'">{{ $t('index.idps.iskam') }}</template>
                                <template v-if="identityProvider === 'guest'">{{ $t('index.idps.guest') }}</template>
                            </strong>
                        </p>
                        <form class="mt-3"  v-on:submit.prevent="register">

                            <!--<div class="form-group">
                                <label for="room">{{ $t('index.select_room') }}</label>
                                <b-form-select id="room" name="room" v-model="room" :options="rooms"></b-form-select>
                            </div>-->

                            <div class="form-group">
                                <b-form-checkbox
                                    id="temporary"
                                    name="temporary"
                                    v-model="temporary">
                                    {{ $t('index.temporary_description') }}
                                </b-form-checkbox>
                            </div>
                            <div class="form-group" v-if="zone === 'znet.vse.cz'">
                                <label for="device">{{ $t('index.device_type') }}</label>
                                <b-form-select id="device" name="device" v-model="device" :options="devices" />
                            </div>

                            <div class="alert alert-warning mb-2 mt-2" v-if="zoneActive">
                                <p>{{ $t('index.already_registered_in_zone') }}</p>
                            </div>

                            <button class="btn btn-primary form-control" @click.prevent="register">
                                {{ $t('index.register_button') }}
                            </button>
                        </form>

                    </div>
                </div>

            </template>

            <template v-if="subnetAwaiting">
                <div class="col-12">
                    <div class="inner">
                        <h2>3. {{ $t('index.processing') }}</h2>
                        <p class="mt-2">{{ $t('index.processing_text_1') }}</p>
                        <p>{{ $t('index.processing_text_2') }}</p>
                        <StatusChecker :historyId="historyId" class="mt-2 mb-2" @registered="registrationBecameActive()"/>
                        <p>{{ $t('index.processing_text_3') }}</p>
                    </div>
                </div>
            </template>

            <template v-if="subnetActive">
                <div class="col-12">
                    <div class="inner">
                        <h2>3. {{ $t('index.done') }}</h2>
                        <p class="mt-2">{{ $t('index.done_text_1') }}</p>
                        <p>{{ $t('index.done_text_2') }}</p>
                    </div>
                </div>
            </template>

        </template>
    </div>
</template>

<script>

    import Header from "../components/Header";
    import Loading from "../components/Loading";
    import InvalidMac from "../components/InvalidMac";
    import Error from "../components/Error";
    import StatusChecker from "../components/StatusChecker";
    import Notice from "../components/Notice";
    import axios from 'axios'

    export default {
        name: "IndexPage",
        components: {
            Notice,
            StatusChecker,
            Error,
            InvalidMac,
            Loading,
            Header
        },
        data() {
            return {
                identityProvider: null,
                authenticationToken: null,
                username: '',
                password: '',

                zone: null,
                ip: null,
                subnet: null,
                mac: null,
                validMac: false,

                zoneActive: null,
                zoneAwaiting: null,
                subnetActive: null,
                subnetAwaiting: null,

                //room: null,
                //rooms: null,
                temporary: false,

                error: false,
                errorMessage: '',

                notice: false,
                noticeMessage: '',

                showFailedAuth: false,

                historyId: null,
                //registered: false,

                device: 'pc',
                devices: [
                    {value: 'pc', text: this.$t('index.devices.pc') },
                    {value: 'printserver', text: this.$t('index.devices.printserver') },
                    {value: 'other', text: this.$t('index.devices.other') }
                ],

                waitingFor: {
                    authentication: false,
                },

                loaded: {
                    networkInfo: false,
                },
            };
        },
        computed: {
            authenticationButtonDisabled() {
                if(this.waitingFor.authentication) {
                    return true;
                }

                if(this.username.length === 0 || this.password.length === 0) {
                    return true;
                }

                return false;
            },
        },
        methods: {
            registrationBecameActive() {
                this.subnetAwaiting = false;
                this.subnetActive = true;
            },
            logout() {
                this.$cookies.remove('token');
                this.$router.go();
            },
            setIdentityProvider(value) {
                this.identityProvider = value;
            },
            register() {
                axios.post('/api/registration/register', {
                    token:      this.authenticationToken,
                    //room:       this.room,
                    temporary:  this.temporary,
                    device:     this.device,
                }).then((response) => {
                    if(response.data.status === 'success') {
                        this.historyId = response.data.data.historyId;
                        this.subnetAwaiting = true;
                        //this.registered = true;
                    } else if(response.data.status === 'notice') {
                        this.notice = true;
                        this.noticeMessage = response.data.message;
                    } else {
                        this.error = true;
                        this.errorMessage = response.data.message;
                    }
                });
            },
            authenticate() {
                this.waitingFor.authentication = true;
                this.username = this.username.trim().toLowerCase();

                // strip the username of @vse.cz
                if(this.username.substring(this.username.length - 7) === "@vse.cz") {
                    this.username = this.username.substring(0, this.username.length - 7);
                }

                axios.post('/api/auth/authenticate', {
                    username: this.username,
                    password: this.password,
                    identityProvider: this.identityProvider,
                }).then((response) => {
                    if(response.data.status === 'success') {
                        if(response.data.data.result === true) {
                            let data = response.data.data;

                            this.authenticationToken = data.token;
                            this.$cookies.set('token', this.authenticationToken, 60 * 60 * 2);

                            this.showFailedAuth = false;

                            //this.loadRooms();
                        } else {
                            this.showFailedAuth = true;
                        }
                    } else if(response.data.status === 'notice') {
                        this.notice = true;
                        this.noticeMessage = response.data.message;
                    } else {
                        this.error = true;
                        this.errorMessage = response.data.message;
                    }
                    this.waitingFor.authentication = false;
                });
            },
            /*loadRooms() {
                axios.post('/api/room/available-rooms', {
                    'token': this.authenticationToken,
                }).then((response) => {
                    let roomOptions = [];
                    let data = response.data.data;
                    for (var i = 0; i < data.length; i++) {
                        roomOptions[i] = {
                            value: data[i].abbreviation,
                            text:  data[i].abbreviation,
                        };
                    }
                    this.rooms = roomOptions;
                });
            },*/
        },
        created() {

            // check for auth via cookie
            if(this.$cookies.isKey('token')) {
                axios.post('/api/auth/via-token', {token: this.$cookies.get('token')}).then((response) => {
                    let data = response.data.data;
                    if(data.result === true) {
                        this.authenticationToken = data.token;
                        this.username = data.username;
                        this.identityProvider = data.identityProvider;

                        this.$cookies.set('token', this.authenticationToken, 60 * 60 * 2);
                        this.showFailedAuth = false;
                    }
                    //this.loadRooms();
                });
            }

            axios.get('/api/info/network').then((response) => {
                if(response.data.status === 'success') {
                    this.mac = response.data.data.mac;
                    this.subnet = response.data.data.subnet;
                    this.zone = response.data.data.zone;
                    this.ip = response.data.data.ip;
                    this.historyId = response.data.data.historyId;
                    this.zoneActive = response.data.data.zoneActive;
                    this.zoneAwaiting = response.data.data.zoneAwaiting;
                    this.subnetActive = response.data.data.subnetActive;
                    this.subnetAwaiting = response.data.data.subnetAwaiting;

                    if(this.zone === 'unet.vse.cz') {
                        this.error = true;
                        this.errorMessage = this.$t('index.errors.unet_not_allowed');
                    }

                    if(this.zone === 'znet.vse.cz') {
                        this.identityProvider = 'vse';
                    }

                    this.loaded.networkInfo = true;
                } else if(response.data.status === 'notice') {
                    this.notice = true;
                    this.noticeMessage = response.data.message;
                } else {
                    this.error = true;
                    this.errorMessage = response.data.message;
                }
            });
        }
    }
</script>