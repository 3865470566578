export default {
    'loading': {
        'text': 'Počkejte prosím, shromažďujeme informace o Vašem zařízení.',
    },
    'notice': {
        'heading': 'Upozornění',
    },
    'error': {
        'heading': 'Vyskytla se chyba',
        'unknown_1': 'Neznámá chyba - neznámý stav aplikace',
        'unknown_2': "Pokud tento problém přetrvá, obraťte se na", 
    },
    'index': {
        'authentication': 'Přihlášení',
        'pick_one': 'Vyberte',

        'idps': {
            'vse': 'Student/zaměstnanec VŠE',
            'vse_text': 'Jsem studentem nebo zaměstnancem Vysoké školy ekonomické v Praze.',
            'vse_auth_info':'Použijte prosím své školní přihlašovací údaje - stejné jako do systému InSIS.',
            'iskam': 'Kolejní účet',
            'iskam_text': 'Nejsem studentem VŠE, ale mám k dispozici účet do sítě hotel.vse.cz.',
            'iskam_auth_info': 'Použijte prosím přístupové údaje do sítě hotel.vse.cz, které můžete získat na recepci své koleje.',
            'guest': 'Účet hosta',
            'guest_text': 'Jsem hostem VŠE a mám k dispozici účet do sítě guest.vse.cz.',
            'guest_auth_info': 'Použijte prosím přístupové údaje do sítě guest.vse.cz, které můžete získat od pověřených školních útvarů.',
        },

        'incorrect_auth': 'Nesprávné uživatelské jméno nebo heslo.',
        'username': 'Uživatelské jméno',
        'password': 'Heslo',
        'authenticate_button': 'Přihlásit se',
        'authenticating': 'Ověřuji...',

        'registration': 'Registrace',

        'logout': 'Odhlásit se',

        'authenticated_as': 'Přihlášen jako',
        'using': 'Pomocí účtu',
        'select_room': 'Vyberte místnost',
        'temporary_description': 'Dočasná registrace (bude ukončena po 12 hodinách)',
        'device_type': 'Typ zařízení',
        'devices': {
            'pc': 'PC / notebook',
            'printserver': 'Tiskárna',
            'study_room_pc': 'Učebnové PC / notebook',
            'server': 'Server',
            'other': 'Ostatní'
        },
        'register_button': 'Registrovat',
        'already_registered_in_zone': 'Toto zařízení je již registrováno v jiné části sítě. Předchozí registrace bude ukončena.',

        'processing': 'Registrace čeká na zpracování',
        'processing_text_1': 'Vyčkejte prosím, než bude registrační proces dokončen. Toto může trvat až 15 minut. Po dokončení bude nutné zařízení restartovat.',
        'processing_text_2': 'S restartem zařízení vyčkejte než k tomu budete vyzváni.',
        'processing_text_3': 'Pokud Vás aplikce k restartu zařízení nevyzve do 15 minut, zařízení restartujte a pokuste se provést registraci znovu. Pokud problém přetrvá, kontaktujte nás na emailu osi@se.cz.',

        'done': 'Registrace dokončena',
        'done_text_1': 'Registrace byla úspěšně dokončena. Restartujte své zařízení.',
        'done_text_2': 'V případě přetrvávajících potíží (nefunkční internet) nás kontaktujte na emailu osi@vse.cz.',

        'checker': {
            'done': 'Registrace byla dokončena. Restartujte své zařízení.',
            'checking': 'Probíhá zjitování stavu.',
            'checking_in': 'Ověření stavu za',
            'seconds': 'sekund.',
        },

        'accommodation_error_info': 'Příčiny této situace mohou být následující:' +
            '<ul>' +
            '<li>Nacházíte se na jiné koleji, než na jaké jste řádně ubytován.</li>' +
            '<li>Od Vašeho ubytování uplynula příliš krátká doba a v našem systému nejste ještě zanesen. Vyčkejte prosím 24h od začátku Vašeho ubytování. Ve většině případů netrvá propsání dat do našeho systému déle než 2 hodiny.</li>' +
            '<li>Přihlašujete se hotelovým účtem, ačkoliv jste studentem VŠE. Studenti VŠE musí jako svůj primární způsob registrace užívat studentské účty, skrze hotelové účty se mohou registrovat pouze hoteloví hosté.</li>' +
            '</ul>',

        errors: {
            'unet_not_allowed': 'Prosím využijte administrátorské aplikace Network (network.vse.cz) pro registraci učebnových zařízení.',
        },

    },
}
