export default {
    'loading': {
        'text': 'Please wait, we are gathering information about your network device.',
    },
    'notice': {
        'heading': 'Notice',
    },
    'error': {
        'heading': 'Error occured',
        'unknown_1': 'Unkown error - unkown state of application.',
        'unknown_2': 'If this problem persists, please contact us at'
    },
    'index': {
        'authentication': 'Authentication',
        'pick_one': 'Pick one',

        'idps': {
            'vse': 'Student or Employee of VŠE',
            'vse_text': 'I am the student or employee of the University of Economics and Business in Prague.',
            'vse_auth_info':'Please use your VŠE username and password - the same you use for InSIS.',
            'iskam': 'Dormitory Account',
            'iskam_text': 'I am not VŠE student, but I have a dormitory network credentials given to me by VŠE\'s dormitory receptionist.',
            'iskam_auth_info': 'Please use your dormitory username and password. You can receive these credentials at the reception of your dormitory.',
            'guest': 'Guest Account',
            'guest_text': 'I have a VŠE guest account for WiFi network guest.vse.cz.',
            'guest_auth_info': 'Please use your guest username and password. You can receive these credentials by various VŠE departments.',
        },

        'incorrect_auth': 'Incorrect username or password.',
        'username': 'Username',
        'password': 'Password',
        'authenticate_button': 'Authenticate',
        'authenticating': 'Authenticating...',

        'registration': 'Registration',

        'logout': 'Sign out',

        'authenticated_as': 'Authenticated as',
        'using': 'Using',
        'select_room': 'Select room',
        'temporary_description': 'Temporary registration (will expire in 12 hours)',
        'device_type': 'Device type',
        'devices': {
            'pc': 'PC / laptop',
            'printserver': 'Printer',
            'study_room_pc': 'Classroom PC / laptop',
            'server': 'Server',
            'other': 'Other'
        },
        'register_button': 'Register the device',
        'already_registered_in_zone': 'This device is already registered in the different segment of the network. The existing registration will be terminated.',

        'processing': 'Registration is being processed',
        'processing_text_1': 'Please wait until the registration process is completed. It may take up to 15 minutes. You will need to restart your device when the process is completed.',
        'processing_text_2': 'Do not restart your device yet. Please wait for our call.',
        'checking': 'Checking.',
        'processing_text_3': 'If the application doesn\'t ask you to restart your device in 15 minutes, the registration process was unsuccessful. Please restart your device and try again or contact us at osi@vse.cz.',

        'done': 'Registration done',
        'done_text_1': 'Registration process was successfully finished. Please restart your device.',
        'done_text_2': 'In case of any problems with the connection, please contact us on email osi@vse.cz.',




        'checker': {
            'done': 'Registration completed. Restart your device.',
            'checking': 'Checking',
            'checking_in': 'Checking in',
            'seconds': 'seconds.',
        },

        errors: {
            'unet_not_allowed': 'Please use Network app (network.vse.cz) to register devices in this subnet.',
        },

    },
}
