<template>
    <div class="col-12 heading">
        <div class="inner">
            <div class="alert alert-danger">
                <h2>{{ $t('error.heading') }}</h2>
                <p class="mt-2" v-if="message">{{ message }}</p>
                <p v-else>{{ $t('error.unknown_1') }}</p>
                <p>{{ $t('error.unknown_2') }} osi@vse.cz.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Error",
    props: ['message']
}
</script>
